import { Badge } from "@/components/ui/badge";
import Image from "next/image";
import parse from "html-react-parser";

export interface Props {
  title?: string;
  parseContent?: string;
  associatedSkills?: string[];
  prerequisitesHtml?: string;
  syllabusHtml?: string;
  formattedLanguage?: string | null;
  formattedTranscripts?: string[];
  learningItems?: {
    sortValue: number | null;
    value: string;
  }[];
  areLearningItemsRTL?: boolean;
}

function formatParagraph(html: string): string {
  let result = html.replace(/<p/g, '<p className="text-gray-800" ');
  return result;
}

export default function WhatYoullLearn({
  title,
  parseContent,
  associatedSkills,
  prerequisitesHtml = "",
  syllabusHtml = "",
  formattedLanguage,
  formattedTranscripts = [],
  learningItems,
  areLearningItemsRTL,
}: Props) {
  const hasList = (parseContent ?? "").includes("</li>");
  const columns = hasList || learningItems ? "md:columns-2" : "md:columns-1";
  parseContent = parseContent?.replace(
    /<li>/g,
    '<li class="flex items-start pl-2 break-inside-avoid-column"><img src="/icons/bullet-point-icon.svg" class="mt-1 mr-4 align-middle"/>',
  );

  return (
    <div className="flex flex-col gap-6">
      {title && (
        <h2 className="my-0 text-primary text-3xl font-bold">{title}</h2>
      )}
      {(parseContent || learningItems) && (
        <div
          className={`
            ${columns} break-keep
            [&>ul]:text-base [&>ul]:text-gray-800 [&>ul]:gap-y-6 [&>ul]:md:gap-y-0 [&>ul]:md:gap-x-14 [&>ul]:px-4
            [&_li:not(:last-child)]:mb-6
          `}
        >
          {parseContent && parse(parseContent)}
          {learningItems && (
            <ul
              // className="list-disc flex flex-col gap-1 list-inside mb-8"
              dir={areLearningItemsRTL ? "rtl" : "ltr"}
            >
              {learningItems?.map((item) => (
                <li
                  key={item.value}
                  className={`flex items-start break-inside-avoid-column ${areLearningItemsRTL ? "pr-2" : "pl-2"}`}
                >
                  <Image
                    src="/icons/bullet-point-icon.svg"
                    alt="Bullet point icon"
                    data-ot-ignore
                    className={`optanon-category-C0001 mt-1 align-middle ${areLearningItemsRTL ? "ml-4" : "mr-4"} `}
                    width={20}
                    height={20}
                  />
                  {item.value}
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
      {associatedSkills && (
        <div className="flex flex-col md:flex-row gap-3">
          {(associatedSkills || []).length > 0 && (
            <div className="md:w-1/2 md:max-w-1/2 flex-shrink-0">
              <h3 className="mb-4 mt-0">Grow these skills</h3>
              <div className="flex flex-row gap-2 flex-wrap">
                {associatedSkills.map((skill) => (
                  <a
                    key={skill}
                    href={`/search?skills.skill=${skill.replace(" ", "+")}`}
                  >
                    <Badge key={skill} className="whitespace-nowrap">
                      {skill}
                    </Badge>
                  </a>
                ))}
              </div>
            </div>
          )}
          <div>
            <h3 className="mb-4 mt-0">Prerequisites</h3>
            {prerequisitesHtml.length > 0 ? (
              <div className="text-gray-800 mb-0">
                {parse(formatParagraph(prerequisitesHtml))}
              </div>
            ) : (
              <p className="text-gray-800 mb-0">None</p>
            )}
          </div>
        </div>
      )}

      {!syllabusHtml && !learningItems && (
        <div className="flex flex-col md:flex-row gap-3">
          {formattedLanguage && (
            <div className="md:w-1/2 md:max-w-1/2 flex-shrink-0">
              <h3 className="mb-4 mt-0">Language</h3>
              <p className="text-gray-800 mb-0">{formattedLanguage}</p>
            </div>
          )}
          {formattedTranscripts?.length > 0 && (
            <div>
              <h3 className="mb-4 mt-0">Transcripts</h3>
              <div className="flex flex-row flex-wrap gap-2">
                {formattedTranscripts.map((transcript, index) => (
                  <p key={index} className="text-gray-800 mb-0 pr-2">
                    {transcript}
                  </p>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
