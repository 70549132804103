"use client";
import React, { FC } from "react";
import IconTextListItem from "@/components/TextLists/IconTextList/IconTextListItem";
import {
  CourseInformation,
  getCourseInformation,
} from "@/lib/course/utils/getCourseInformation";
import { Skeleton } from "@/components/ui/skeleton";
import { OverviewItem, Props } from "./types";
import { getProgramInformation } from "@/lib/course/utils/getProgramInformation";

type CombinedInformation = CourseInformation & Partial<OverviewItem>;

const Loader: FC = () => (
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
    {Array.from({ length: 4 }).map((_, i) => (
      <div key={i} className="flex items-center space-x-4">
        <Skeleton className="h-12 w-12 rounded-full" />
        <div className="space-y-2">
          <Skeleton className="h-4 w-24" />
          <Skeleton className="h-4 w-32" />
        </div>
      </div>
    ))}
  </div>
);

export default function FactoidBar({
  courseData,
  paymentOption,
  hasDateColumn,
  isArchivedExternally = false,
  leadWithPaidData,
  monarchFactoidsBar,
  isFactoidsBarFieldsLoading,
}: Props) {
  const { displayFactoidsIcons, isJumpLinkActive, jumpLinkUrl, urlText } =
    monarchFactoidsBar || {};

  const isMultipleCourses = Array.isArray(courseData);
  // TODO: replace variable with whatever field is created to handle the hero side rail display
  const isSideRailActive = false;
  let courseInformation: CourseInformation[] = [];

  if (isMultipleCourses) {
    courseInformation = getProgramInformation(courseData);
  } else if (!isMultipleCourses && paymentOption) {
    courseInformation = getCourseInformation(
      courseData,
      paymentOption,
      hasDateColumn,
      isArchivedExternally,
      true,
    );
  }

  const leadWithPaidTest = leadWithPaidData?.value;
  const isLoading = leadWithPaidData?.isLoading ?? false;

  const overview = leadWithPaidTest?.overview ?? {};

  const combinedCourseInformation: CombinedInformation[] = [
    ...courseInformation,
    ...Object.values(overview).map((overviewItem) => ({
      header: overviewItem.overviewHeader,
      description: overviewItem.overviewDescription ?? "",
      icon: overviewItem.overviewIcon,
      overviewLink: overviewItem.overviewLink,
    })),
  ];

  const factoidsData = isSideRailActive
    ? combinedCourseInformation.slice(0, 3)
    : combinedCourseInformation;

  const IconTextListItems: FC = () =>
    factoidsData.map((item) => {
      let iconSize = "42";
      let linkClass = "";
      if (item.icon === "certificate") {
        iconSize = "50";
        linkClass = "text-brand";
      }

      const selectedLink = item.overviewLink
        ? isJumpLinkActive
          ? (jumpLinkUrl ?? item.overviewLink)
          : undefined
        : undefined;
      const descriptionText =
        urlText ?? item.overviewDescription ?? item.description;

      return (
        <IconTextListItem
          key={item.icon}
          icon={
            displayFactoidsIcons ? (item.overviewIcon ?? item.icon) : undefined
          }
          header={item.overviewHeader ?? item.header}
          boldHeader={true}
          description={descriptionText}
          small
          className={`grow-0 pb-0 ${
            factoidsData.length > 3
              ? "flex-grow basis-1/4 md:justify-items-center"
              : "flex-grow basis-1/3 md:justify-items-center"
          }`}
          link={selectedLink}
          textContainerClassName="md:pr-0"
          headerClassName="text-[16px]"
          descriptionClassName="text-[14px]"
          iconSize={iconSize}
          linkClassName={linkClass}
        />
      );
    });

  return (
    <div className="w-full bg-putty-100 border-y-8 border-x-1 border-putty-500 rounded-t-[12px] rounded-b-[12px] p-6">
      <div
        className={`flex flex-col gap-6 md:grid ${factoidsData.length > 3 ? "md:grid-cols-4" : "md:grid-cols-3"}`}
      >
        {isLoading || isFactoidsBarFieldsLoading ? (
          <Loader />
        ) : (
          <IconTextListItems />
        )}
      </div>
    </div>
  );
}
