import React from "react";

interface CourseRatingProps {
  count: number;
  average: number;
  variant?: "outlined" | "solid";
  ratingClassName?: string;
}

function CourseRating({
  count,
  average,
  variant = "outlined",
  ratingClassName = "",
}: CourseRatingProps) {
  const courseRating = Number(average).toFixed(1);
  const percent = (Number(courseRating) / 5) * 100;

  const isOutlined = variant === "outlined";
  const gradient = isOutlined
    ? `linear-gradient(to right, #00261f ${percent}%, transparent ${percent}%)`
    : `linear-gradient(to right, #d74000 ${percent}%, #F49974 ${percent}%)`;
  const fontSize = variant === "outlined" ? "text-sm" : "";

  return (
    <div className="flex items-center gap-2">
      {!isOutlined && (
        <div className={`font-bold ${ratingClassName}`}>{courseRating}</div>
      )}
      <div className="relative">
        <div className="text-transparent">
          <span className={`block tracking-wide text-gray-400 ${fontSize}`}>
            ☆☆☆☆☆
          </span>
        </div>
        <div
          className={`absolute inset-0 tracking-wide ${fontSize}`}
          style={{
            background: gradient,
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
          aria-label={`Rating of this course is ${courseRating} out of 5.`}
          role="status"
        >
          ★★★★★
        </div>
      </div>
      {isOutlined && (
        <div className={`ml-2 font-medium text-primary ${fontSize}`}>
          {courseRating} stars
        </div>
      )}
      <div className={`text-gray-800 ${fontSize}`}>
        {count} {isOutlined ? "ratings" : "reviews"}
      </div>
    </div>
  );
}

export default CourseRating;
